import profile from "../src/images/profile.png";
import cp from "../src/images/cp.png";
import lpu from "../src/images/lpu.png";
import cylerian from "../src/images/cylerian.png";
import adobe from "../src/images/adobe.png";
import cyware from "../src/images/cyware.png";
import sprinkle from "../src/images/sprinkle.jpeg";
import ufl from "../src/images/ufl.png";
import ragu from "../src/images/ragu.jpeg";
import kaushik from "../src/images/kaushik.jpeg";
import hai from "../src/images/hai.jpeg";
import akash from "../src/images/akash.png";
import campaign from "../src/images/campaign.png";

const header = {
  homepage: "https://sauravprofile.com",
  title: "SP",
};

const links = {
  calpoly: "https://www.calpoly.edu",
  lpu: "https://www.lpu.in",
  adobe: "https://www.adobe.com",
  cylerian: "https://www.cylerian.com",
  cyware: "https://cyware.com",
  sprinkle: "https://www.sprinkledata.com",
  ufl: "https://www.ufl.edu",
};

const about = {
  image: profile,
  name: "Saurav Gupta",
  role: "Software Engineer @ Adobe |  Cal Poly alumnus",
  gender: "(He/Him)",
  campaign: campaign,
  campaignLink: "https://topmate.io/meetsaurav",
  description:
    "I recently graduated from California Polytechnic State University, San Luis Obispo, with a master's degree in computer science. I am a full-stack software engineer with a passion for programming, possessing a robust understanding of data structures, algorithms, and object-oriented design, acquired through hands-on experience and academic rigor",
  address: "Lehi, Utah, United States",
  // resume:
  //   "https://drive.google.com/file/d/1OC7fMg4HtZEV3grefN4AOcGLgVfSR6Wg/view?usp=share_link",
  social: {
    linkedin: "https://www.linkedin.com/in/sauravgupta2800",
    github: "https://github.com/sauravgupta2800",
    youtube: "https://www.youtube.com/@NamasteSaurav",
    instagram: "https://www.instagram.com/sauravgupta2800",
  },
};

const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "React JS",
  "Vue JS",
  "AngularJS",
  "Redux",
  "Next JS",
  "Tailwind",
  "Kotlin",
  "Android",
  "Go",
  "Material-UI",
  "Git",
  "Web Accessibility",
  "Responsive Design",
  "Build Tools",
  "GraphQL",
  "CI/CD",
  "Jest",
  "MySQL",
  "PostgreSQL",
  "MongoDB",
  "Java",
  "Python",
  "C/C++",
  "NodeJS",
  "Flask",
  "ExpressJS",
  "MongoDB",
  "Docker",
  "Kubernetes",
  "AWS",
  "RESTful APIs",
  "Unix",
  "Linux",
  "Database",
  "Teamwork",
  "Problem-solving",
  "time management",
  "Algorithms",
];

const educations = [
  {
    logo: cp,
    link: links.calpoly,
    name: "California Polytechnic State University-San Luis Obispo",
    degree: "Master of Science in Computer Science",
    dates: "September 2022 - December 2023",
    gpa: "3.97/4",
    achievements: [
      "Graduated with Distinction, awarded to the top 10% of master's candidates with a GPA of 3.75+",
      "Secured admission to numerous prestigious hackathons, such as CruzHacks at UC Santa Cruz, LAHacks at UC Los Angeles, HackSC at USC, and CalHacks at UC Berkeley",
      "As the teaching assistant, I guided undergraduate students in two classes: data structures and algorithms in Python (CSC 202) and led project-based object-oriented programming and design in Java (CSC 203)",
      "Participated vigorously in a range of academic clubs, such as the ISF (International Student Friendship) Club, the Cal Poly Van Life Club, and the Cal Poly Hiking and Backpacking Club",
    ],
  },
  {
    logo: lpu,
    link: links.lpu,
    name: "Lovely Professional University",
    degree: "Bachelor of Technology - BTech, Computer Science",
    dates: "August 2016 - July 2020",
    gpa: "9.54/10",
    achievements: [
      "Attained two consecutive University Academic Honor Roll recognitions for achieving top GPAs in the computer science department",
      "Participated in the Grand Finale of Smart India Hackathon as one of the top 50 out of 10,000 teams from across India, accredited by the Government of India",
      "Recipient of the first place award in the Frontend Coding Challenge during the Webathon, organized by Lovely Professional University",
    ],
  },
];

const experiences = [
  {
    company: "Adobe",
    logo: adobe,
    link: links.adobe,
    color: "#f44336",
    position: "Software Engineer 3",
    dates: "Feb 2024 – Present",
    address: "Lehi, Utah, United States · On-site",
    description:
      "Working with the data collection team under Adobe Experience Cloud as a backend engineer to handle billions of the data hits per day",

    skills: ["Kubernetes", "AWS", "Go", "Java", "Git", "Javascript"],
  },
  {
    company: "Cylerian",
    logo: cylerian,
    link: links.cylerian,
    color: "#2978b5",
    position: "Software Engineer Intern",
    dates: "September 2023 – December 2023",
    address: "San Luis Obispo, California, United States · On-site",
    description:
      "Actively contributed to production features and reported directly to the CEO, gaining invaluable full-stack experience, which encompassed roles involving Docker CI/CD. This journey provided me with a deeper understanding of the extensive customization possibilities that websites offer, marking an exciting and novel facet of my exploration into the world of frontend development.",
    skills: ["ReactJS", "NodeJS", "Docker", "Unix", "CI/CD", "Github"],
  },
  {
    company: "Adobe",
    logo: adobe,
    link: links.adobe,
    color: "#f46936",
    position: "Software Engineer Intern",
    dates: "June 2023 – September 2023 (3 mos)",
    address: "San Jose, California, United States · On-site",
    description:
      "Spearheaded innovative initiatives, including a Proof of Concept (POC) for Frictionless Legal Templates. This endeavor significantly improved accessibility, enhanced user onboarding through a product-led growth (PLG) approach, and boosted the Click-Through Rate (CTR) from 2% to an impressive 5%",

    skills: [
      "Collaboration",
      "Communication",
      "Attention to Detail",
      "ReactJS",
      "Javascript",
      "Git",
      "Documentation",
    ],
  },
  {
    company: "Cyware Labs",
    logo: cyware,
    link: links.cyware,
    color: "rgb(200 97 233)",
    position: "Software Engineer - II",
    dates: "March 2020 – August 2022  (2 yrs 6 mos)",
    address: "Bengaluru, Karnataka, India · On-site",
    description:
      "During my 2+ years at a fast-growing mid-size product startup, I not only received invaluable mentorship but also had the privilege to make a global impact through my work, while orchestrating the development of numerous features across two diverse teams",
    resposibilities: [],
    skills: [
      "VueJS",
      "Vuex",
      "Webpack",
      "Javascript",
      "Typescript",
      "API Integration",
      "Jira",
      "Figma",
      "Jest",
      "Web Accessibility",
      "Web Performance",
      "SocketIO",
    ],
  },
  {
    company: "Sprinkle Data",
    logo: sprinkle,
    link: links.sprinkle,
    color: "#0d8fff",
    position: "Software Engineer Intern",
    dates: "June 2019 – March 2020 (9 mos)",
    address: "Bengaluru, Karnataka, India · On-site",
    description:
      "Launched my IT career at a dynamic startup, mentored by the CEO. Collaborating with a team of six, we transformed our frontend product from JQuery to ReactJS, boosting web load performance from 55% to an impressive 85%, propelling my professional journey forward",
    skills: [
      "HTML",
      "CSS",
      "npm",
      "ReactJS",
      "Javascript",
      "JQuery",
      "Java",
      "MySQL",
      "Git",
    ],
  },
];

const projects = [
  {
    name: "Frontend Tools",
    description:
      "A versatile tool for frontend developers that empowers customization, allowing them to curate their own suite of essential tools, free from distractions and advertisements",
    stack: ["ReactJS", "NodeJS", "ExpressJS", "SASS"],
    sourceCode: "https://github.com/sauravgupta2800/frontendtoolkit-frontend",
    livePreview: "https://frontend-toolkit-sauravgupta2800.vercel.app/",
  },
  {
    name: "Polyratings",
    description:
      "Crafted to serve as an easily accessible and user-friendly application for Cal Poly students to voice their insights and evaluations regarding their courses and professors",
    stack: ["Kotlin", "UX/UI", "Java", "Android App"],
    sourceCode: "https://github.com/sauravgupta2800/Polyratings",
    livePreview:
      "https://www.linkedin.com/in/sauravgupta2800/details/projects/1343789099/multiple-media-viewer/?profileId=ACoAAB8EQK0B6gTTVImTS9oIY42NWgmbDu5dvcs&treasuryMediaId=1635545811719",
  },
  {
    name: "Smart Assessment",
    description:
      "An intelligent and adaptable coding interview platform that utilizes affective emotional signals, captured through the interviewee's electroencephalography (EEG) data",
    stack: ["Machine Learning", "SocketIO", "OOPs"],
    sourceCode:
      "https://github.com/sauravgupta2800/Smart-Assessment-Affective-Computing-Project",
    livePreview:
      "https://drive.google.com/file/d/1-zN0vIucOMmM8321RopE-pFHiHeOM108/view?usp=sharing",
  },
  {
    name: "Coding Patterns",
    description:
      "A webapp contains 21 coding patterns and a total of 125 questions for master tech interviews",
    stack: ["Data Structure & Algorithms", "Javascript"],
    sourceCode: "https://github.com/sauravgupta2800/coding-patterns",
    livePreview: "https://www.codingpatterns.com/",
  },
  {
    name: "American Sign Language",
    description:
      "This application converts different ASL hand signs to letters using Deep Learning model",
    stack: ["Flask", "Python", "JavaScript", "ML", "DL"],
    sourceCode:
      "https://github.com/Hadiasemi/American-Sign-Language-Translator-Web-Application",
  },
  {
    name: "MCTS for Snakes",
    description:
      "Applied Monte Carlo Tree Search (MCTS) to optimize gameplay in the 2020 IEEE CoG Snakes Competition",
    stack: ["Java", "Artificial Intelligence", "Graph Algo"],
    sourceCode: "https://github.com/rkthomps/MCTSSnake",
  },
  // {
  //   name: "GoBlog",
  //   description:
  //     "Created an end-to-end web app with CRUD, user auth, email notifications, password reset, comments, encryption, and Git",
  //   stack: ["Python", "Javascript", "HTML", "CSS", "API"],
  //   sourceCode: "https://github.com/sauravgupta2800/GoBlog",
  // },
];

const recommendations = [
  {
    profile: ragu,
    name: "Ragunandan Rao Malangully",
    linkedin: "https://www.linkedin.com/in/ragunandanrao",
    position: "Engineering Manager",
    company: "Adobe",
    logo: adobe,
    link: links.adobe,
    description: `Saurav is an asset to any team he is part of. We had first hand experience of this when he interned at our team in Adobe. \n
     His skills with React and Web programming were very good. He was able learn newer concepts of Java based REST APIs, CI/CD and micro front-ends very quickly and well I should add. \n
     His diligence along with his unique perspective on problem solving is something I learned from him. He always made sure he asked the right questions which made his inquisitiveness along with customer empathy evident. He collaborated with cross team members to go above and beyond on his summer intern project. The team was thoroughly impressed with his skills and commitment. I would vouch for his skills and would recommend him to other employers looking for great candidates `,
  },
  {
    profile: kaushik,
    name: "Kaushik Chandell",
    linkedin: "https://www.linkedin.com/in/kaushikchandell",
    position: "Ex-Product Manager",
    company: "Cyware Labs",
    logo: cyware,
    link: links.cyware,
    description: `I watched Saurav develop from an intern to a software leader who was not only learning and moving up the corporate ladder with his incredible work but also assisting and mentoring upcoming software professionals. Saurav is a very trustworthy team member that continually broadens his skill set and product or technological knowledge. \n
    He is intelligent, reliable, aware of his priorities, and a breeze to talk to and work with. His inquisitive temperament has always pushed me when it comes to devising a solution and has frequently assisted me in filling any voids that would have easily crept into the final product. Saurav would respond to each problem statement with multiple solutions and their impact, scenarios, pros, and cons`,
  },
  {
    profile: hai,
    name: "Hai Jung Hsu",
    linkedin: "https://www.linkedin.com/in/hai-jung-hsu",
    position: "Software Engineer",
    company: "Adobe",
    logo: adobe,
    link: links.adobe,
    description: `I worked with Saurav during his time at Adobe as an intern. I was his mentor and I can confidently recommend Saurav as a capable engineer and a great team player. I was impressed time and time again by his problem solving ability. Saurav is also a fast learner and able to pick up new tools and utilize them. It was an absolute pleasure to work with Saurav!`,
  },
  {
    profile: akash,
    name: "Akash Kumar",
    linkedin: "https://www.linkedin.com/in/akash-kumar916",
    position: "Software Engineer & Grad Student",
    company: "University of Florida",
    logo: ufl,
    link: links.ufl,
    description: `Saurav might be the most analytical person I know. He is always fast to respond, intelligent, and comes up with creative solutions to difficult problems. He is an exceptional learner and his knowledge of Frontend technologies is very extensive. We have been in many hackathons together in our college days where he showed his strong technical skills and drive the team. I love his positive attitude and he is my go-to person when I need advice`,
  },
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: "saurav.calpoly@gmail.com",
};

export {
  header,
  about,
  projects,
  skills,
  educations,
  experiences,
  contact,
  recommendations,
};
