import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <a
      href={"https://www.linkedin.com/in/sauravgupta2800/"}
      target="_blank"
      className="link footer__link"
      rel="noreferrer"
    >
      Designed with ❤️ by Saurav Gupta
    </a>
  </footer>
);

export default Footer;
